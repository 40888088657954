import { pipelinesLDFeatureKeys } from './pipelines-flags';

export const FeatureKeys = {
  ...pipelinesLDFeatureKeys,
  isExampleFlag: 'example-flag',
  bbogDiscoverExperiment: 'bb.og.discover',
  useExperimentApiRecommendations: 'use-experiment-api-recommendations',
  isNudgeReviewersEnabled: 'pr-nudge-reviewers',
  isProjectSettingsInheritanceProviderEnabled:
    'project-settings-inheritance-provider',
  isBuildLinkRedirectionFixEnabled: 'build-link-redirection-fix',
  isPrDependenciesEnabled: 'pr-dependencies',
  isPrReviewGroupsEnabled: 'pr-review-groups',
  sumCreateWorkspacePreChangeEnabled:
    'ADMINX-1261-workspaces-list-sum-pre-change',
  descriptivePrUpdateFlag: 'descriptive-pr-update-flag',
  extensibleMergeChecksEnabled: 'extensible-merge-checks-enabled',
  clearPrFilter: 'clear-pr-filter',
  cookieConsentBannerEnabled: 'platform.moonjelly.browser-storage-controls',
  postOfficeInAppSwitcher: 'post-office-in-bitbucket-switcher',
  markFilesNewFeatures: 'mark-files-new-features',
  compassEditableEmptyState: 'bbc-compass-new-empty-state-side-card',
  compassXpSearchNonAdmin: 'compass-in-cross-product-search-non-admin',
  disableCompassBitbucketRepositoryCard:
    'disable-compass-bitbucket-repository-card',
  isDefaultDisableFlagsForPdvsEnabled: 'default-disable-flags-for-pdvs',
  enableAutoreviewFeedbackUi: 'autoreview-feedback-ui_0ya4n',
  enableChoreographer: 'post-office_enable_bitbucket_choreographer_98iep',
  staleBranchesFilter: 'stale-branches-filter',
  isNavThemeSelectorEnabled: 'enable-navigation-color-theme-picker',
  preventForksOutsideWorkspace: 'prevent-forking-outside-workspace',
  projectWorkspaceCustomMergeChecks:
    'project-and-workspace-custom-merge-checks',
} as const;

export type FeatureKeyType = (typeof FeatureKeys)[keyof typeof FeatureKeys];

export const pipelinesStatsigKeys = {
  inlineStopButtonEnabled: 'bitbucket_pipelines_pipelines_list_action_enabled',
  isPipelinesStepFilterEnabled: 'enable_pipelines_step_filter',
  isImprovedLogStreamingRenderingEnabled:
    'bitbucket_pipelines_improve_log_streaming_renders_',
  websocketOptimisationsEnabled: 'bitbucket_pipelines_websocket_optimisations',
  isPipelinesNavigationDropdownEnabled:
    'bitbucket_pipelines_navigation_dropdown_enabled',
  autoSelectBranchInModalEnabled:
    'bitbucket_pipelines_select_branch_pipeline_modal',
};

export const StatsigFeatureKeys = {
  ...pipelinesStatsigKeys,
  bbcNewNav: 'bbc_new_nav',
  prCreateDeleteBranchDefault: 'bbc-frontbucket-default-delete-branch',
  useConsolidatedForgeRenderer: 'forge-ui-use-consolidated-renderer',
  fixTableStyle: 'bcloud-20528-commits-style',
} as const;

export type StatsigFeatureKeyType =
  (typeof StatsigFeatureKeys)[keyof typeof StatsigFeatureKeys];
